import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { container } from "../../../components/animation/landingAnimation";
import { tabData } from "../../../constants/pricingConstant";
import { background } from "../../../resources/js/images";
import { homeVideoUrl } from "../../../resources/js/videos";
import {
    headingsTwo,
    slideOpacityLeft,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import { Carousel } from "../../../shared/ui/Carousel";
import Hero from "../../home/Hero";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";

const PricingHeroAndContent = ({ video = false }) => {
    const [active, setActive] = useState(tabData[0]);
    const [showModal, setShowModal] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (!location) {
            setActive(tabData[0]);
        } else {
            let data = tabData.find((item) => item.path === location.pathname);
            setActive(data);
        }
    }, [location]);

    const renderImages = (data) => {
        return data.map((item, i) => (
            <div key={i} className="flex flex-wrap justify-between items-start">
                <div className="w-full xl:w-[40%] mb-3.2">
                    {item?.subHeading && (
                        <H3 className="mt 1.6 mb-0.8 sm:mt-2.4 sm:mb-1.6 xl:my-0.8">
                            {item?.subHeading}
                        </H3>
                    )}
                    <P className="text-secondary">{item?.subText}</P>
                </div>
                <div className="w-full xl:w-[50%]">
                    <img
                        className="w-full aspect-video rounded-sm sm:rounded-md  2xl:rounded-2"
                        src={item?.sectionImg?.img}
                        alt={item.sectionImg.alt}
                    />
                </div>
            </div>
        ));
    };

    return (
        <div>
            <Hero
                url={video ? homeVideoUrl : background}
                type={video ? "video" : "img"}
                wrapperClass=" xl:h-[830px]  2xl:h-screen"
                content={
                    <Container>
                        <div
                            style={{
                                paddingTop:
                                    "clamp(3rem, calc((100vw - 1439px) * 80), 5rem)",
                            }}
                        >
                            <span className="text-gray-250 text-base">
                                Our Solar for
                            </span>
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={translateContainer}
                                className="text-white w-full 
                        2xs:w-[94%] sm:w-[600px] md:w-[680px] lg:w-[700px] xl:w-[810px] 2xl:w-[1100px] 3xl:w-[1200px]
                        "
                            >
                                <SplitText
                                    className="gap-x-0.8 sm:gap-x-1.2 flex flex-wrap text-white "
                                    variants={wavyHeadings}
                                >
                                    {active.name}
                                </SplitText>
                            </motion.div>

                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={container}
                                className="overflow-x-hidden"
                            >
                                <motion.div variants={headingsTwo}>
                                    <H3 className="font-normal text-white   sm:text-[2.6rem] lg:text-5xl">
                                        {active.tagLine}
                                    </H3>
                                </motion.div>

                                <motion.div variants={slideOpacityLeft}>
                                    <Button
                                        variant="secondary"
                                        className="mt-2.4 xl:mt-2.4 2xl:mt-4.8"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Get quote
                                        <span>
                                            <svg
                                                width="13"
                                                height="14"
                                                className="  w-[14px] h-[14px]  lg:w-[18px] lg:h-[18px] xl:w-[24px] xl:h-[24px] "
                                                viewBox="0 0 13 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    x="0.38162"
                                                    y="0.9866"
                                                    width="12.0268"
                                                    height="12.0268"
                                                    rx="6.01338"
                                                    stroke="currentColor"
                                                    strokeWidth="0.76324"
                                                />
                                                <path
                                                    d="M4 9.39498L8.79 4.60498M8.79 4.60498H4.479M8.79 4.60498V8.91598"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </motion.div>
                            </motion.div>
                        </div>
                    </Container>
                }
            />
            <Section>
                <Container>
                    <H2
                        className={`${
                            active?.subHeading ? "" : "mb-0.8 xl:mb-3.2"
                        }`}
                    >
                        {active.heading}
                    </H2>
                    {active?.carousel && (
                        <Carousel
                            slides={renderImages(active?.carousel)}
                            headclass="h-full"
                            autoSlide={true}
                            flexPos="justify-start lg:justify-start"
                            autoSlideInterval={3000}
                        />
                    )}
                </Container>
            </Section>

            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
                disabled={false}
            />
        </div>
    );
};

export default PricingHeroAndContent;
