import React, { useState } from "react";
import Accordion from "../../../../components/accordion/Accordion";
import {
    investFaq,
    investMiscellaneousFaq,
    investRiskFaq,
    kycFaq,
    opporTunityTabfaqs,
    opportunityFaq,
    payoutTaxesFaq,
    propositionFaq,
    referralProgramFaq,
} from "../../../../constants/faqData";
import community from "../../../../resources/images/comminity.png";
import {
    featureImg1,
    featureImg2,
    featureImg3,
    featureImg4,
} from "../../../../resources/js/icons";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import H2 from "../../../../shared/typography/H2";
import P from "../../../../shared/typography/P";
import FeatureCard from "../../../../shared/ui/Cards/FeatureCard";
import Tab from "../../../../shared/ui/tab/Tab";
import Hero from "../../Hero";
import MultiStepForm from "./MultiStep";
import Calculation from "./calculation";
const features = [
    {
        title: "Reduced Electricity Bills",
        description:
            "Solar energy can significantly lower your electricity costs by generating power for your home.",
        icon: featureImg1,
    },
    {
        title: "Good for Environment",
        description:
            "Solar power is a clean, renewable energy source that reduces greenhouse gas emissions and reliance on fossil fuels.",
        icon: featureImg2,
    },
    {
        title: "Low Maintenance Costs",
        description:
            "Solar energy systems require minimal maintenance. Most systems come with long-term warranties, and routine cleaning.",
        icon: featureImg3,
    },
    {
        title: "Energy Independence",
        description:
            "By producing your own electricity, you become less dependent on traditional power sources.",
        icon: featureImg4,
    },
];
let allFaqs = [
    ...investFaq,
    ...investMiscellaneousFaq,
    ...investRiskFaq,
    ...kycFaq,
    ...opporTunityTabfaqs,
    ...opportunityFaq,
    ...payoutTaxesFaq,
    ...propositionFaq,
    ...referralProgramFaq,
];
const FaqItem = ({ question, answer, list, format }) => {
    return (
        <Accordion
            labelClass="font-semibold text-primary  text-sm sm:text-base xl:text-base 2xl:text-xl"
            key={question}
            label={question}
            size="s"
            wrapperClass="bg-white p-0.8 mb-1.6 lg:p-1.6 lg:mb-3.2"
        >
            {answer ? (
                <P
                    className={`${
                        list ? "mb-0.5 " : "mb-0"
                    } text-secondary font-normal   text-sm sm:text-base xl:text-base 2xl:text-lg`}
                >
                    {answer}
                </P>
            ) : null}
            {format ? (
                <P
                    dangerouslySetInnerHTML={format}
                    className=" text-secondary font-semibold   text-sm sm:text-base xl:text-base 2xl:text-lg"
                    style={{
                        marginBottom: list ? "5px" : "0px",
                        marginTop: answer ? "5px" : "0px",
                    }}
                ></P>
            ) : null}
            {list
                ? list.map((item, index) => (
                      <div
                          className="flex mb-0.5"
                          key={index}
                          style={{ marginBottom: "5px" }}
                      >
                          <div>
                              <P className="w-[15px] mr-1text-secondary font-semibold   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {`${index + 1}.`}
                              </P>
                          </div>
                          <div className="flex-1">
                              <P className="text-secondary  font-semibold   text-sm sm:text-base xl:text-base 2xl:text-lg">
                                  {item}
                              </P>
                          </div>
                      </div>
                  ))
                : null}
        </Accordion>
    );
};
const Community = () => {
    const [active, setActive] = useState(filteredItem[0]);
    const [displayFaq, setDisplayFaq] = useState(investFaq);

    const renderFaq = (data, value) => {
        return displayFaq.map(
            (item, i) =>
                i % 2 === value && (
                    <FaqItem
                        key={item?.question}
                        list={item?.list}
                        question={item?.question}
                        answer={item?.answer}
                        format={item?.format}
                    />
                )
        );
    };
    const handleFilterFaq = (data) => {
        setActive(data);
        let newFaqs = allFaqs.filter((item) => item?.tags?.includes(data.name));
        setDisplayFaq(newFaqs.slice(0, 10));
    };
    return (
        <div>
            <div className="relative">
                <Hero
                    url={community}
                    wrapperClass="relative h-[180px] sm:h-[180px] xl:h-[356px]"
                />
                <Section className="relative z-10 mt-[-150px] lg:mt-[-350px]">
                    <Container>
                        <div
                            className="bg-white p-3 md:px-7 shadow-lg rounded-lg
                    "
                        >
                            <div className="flex justify-between gap-1.2 flex-col lg:flex-row items-center lg:gap-2 mb-3.2">
                                <div className="w-full lg:w-[35%] xl:w-[30%] 2xl:w-[35%]">
                                    <H2>Why Solar is Important?</H2>
                                </div>
                                <div className="w-full lg:w-[65%] xl:w-[45%]">
                                    <P className="text-secondary">
                                        We are at the forefront of the renewable
                                        energy revolution, specializing in
                                        high-quality, cost-effective solar power
                                        solutions designed to meet the diverse
                                        needs of both urban and rural
                                        communities.
                                    </P>
                                </div>
                            </div>
                            <div className="grid gap-x-1.6 gap-y-2.4 sm:gap-y-3.2 grid-cols-2 lg:grid-cols-4 xl:gap-x-3.6 2xl:gap-x-3.8">
                                {features.map((feature, index) => (
                                    <FeatureCard
                                        feature={feature}
                                        key={index}
                                        index={index}
                                    />
                                ))}
                            </div>
                        </div>
                    </Container>
                </Section>
            </div>
            <Calculation />
            <MultiStepForm />
            <Section>
                <Container>
                    <div className="lg:flex justify-between  items-center gap-9 lg:mb-5 xl:mb-6.4">
                        <div className="w-full  xl:w-[40%] 2xl:w-[40%] mb-0.8 lg:mb-0">
                            <H2>Frequently asked Questions</H2>
                        </div>
                        <div className="xl:flex-1">
                            <P className="text-secondary">
                                In publishing and graphic design, Lorem ipsum is
                                a placeholder text commonly used to demonstrate
                                the visual form of a document or a typeface
                                without relying on meaningful content.
                            </P>
                        </div>
                    </div>

                    <div className="flex flex-nowrap overflow-x-auto  xl:w-[70%] gap-2.4 no-scrollbar mb-2.4 xl:mb-5.6 mt-2.4 lg:mt-0">
                        {filteredItem.map((item, i) => (
                            <Tab
                                active={item.name === active.name}
                                key={item.id}
                                text={item.name}
                                onClick={() => handleFilterFaq(item)}
                            />
                        ))}
                    </div>

                    <div className="lg:flex justify-between gap-3.2">
                        <div className="flex-1">{renderFaq(investFaq, 0)}</div>
                        <div className="flex-1">{renderFaq(investFaq, 1)}</div>
                    </div>
                </Container>
            </Section>
        </div>
    );
};

export default Community;
const filteredItem = [
    {
        id: 1,
        name: "Trending",
    },
    {
        id: 2,
        name: "Invest",
    },

    {
        id: 3,
        name: "Technology",
    },
    {
        id: 4,
        name: "Government Subsidy",
    },
    {
        id: 5,
        name: "Co2 Reduced",
    },
    {
        id: 6,
        name: "Srot",
    },
    {
        id: 8,
        name: "Solar",
    },
];
