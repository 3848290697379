import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { quotesWhite } from "../../assets";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/button/Button";
import Link from "../../components/link/Link";
import Space from "../../components/space/Space";

import { Flex, Section } from "../../components/styles/Elements.styles";

import Hero from "../../app/home/Hero";
import { listOfArticles } from "../../constants/blogData";
import { NOT_FOUND } from "../../constants/routes";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
const TOCContainer = styled.div`
    margin: 2rem 0;
    padding: 1rem;
    border-radius: 0.8rem;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 0.5rem;
        cursor: pointer;
        color: ${(props) => props.theme.color.primary};

        &:hover {
            text-decoration: none;
        }
    }
`;
const Wrapper = styled.div`
    height: 350px;
    width: 100%;
    position: relative;
    overflow-x: hidden;

    img {
        object-fit: cover;
        object-position: left center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -2;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        object-position: top center;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        height: 400px;
    }
`;

const Quotes = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.color.primary};
    padding: 1rem;
    border-radius: 0.8rem;
    margin: 2rem 0;

    .icon {
        width: 50px;
        margin-bottom: 1rem;
    }
    ${H3} {
        line-height: 1.5;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        flex-direction: row;
        align-items: center;
        .icon {
            width: 80px;
            margin: 0 2rem 0 0;
        }
    }
`;

const Tag = styled(P)`
    padding: 0.2rem 0.8rem;
    background: ${(props) => props.theme.color.grey};
    margin: 1rem 0.5rem 0 0;
    border-radius: 50px;
`;

const LinkedinButton = styled(Button)`
    :active,
    :focus {
        box-shadow: none;
    }
`;

const Subscribe = () => {
    return (
        <Link
            isExternal={true}
            to="https://www.linkedin.com/newsletters/7034791305847160832/?displayConfirmation=true"
        >
            <LinkedinButton type="solid" size="s">
                <Space xxsm={["0 .21rem 0 0"]}>
                    <Icon size="s" component={FaLinkedinIn} color={"white"} />
                </Space>
            </LinkedinButton>
        </Link>
    );
};
const FlexItem = styled(Flex.Item)`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const BlogDetails = () => {
    const { id } = useParams();
    const history = useHistory();
    const articlesDetails1 = listOfArticles.find(
        (article) => article.meta.blogUrl === id
    );

    if (!articlesDetails1) {
        history.push(NOT_FOUND);
        return null;
    }

    const renderContent = (content) => {
        return content.map((item, index) => {
            switch (item.type) {
                case "text":
                    return (
                        <P
                            key={index}
                            className={cn("my-1", item?.className)}
                            dangerouslySetInnerHTML={{
                                __html: item.value,
                            }}
                        />
                    );
                case "quote":
                    return (
                        <Quotes key={index}>
                            <div className="icon">
                                <img
                                    src={quotesWhite}
                                    alt="Quote Icon"
                                    className="w-8 h-3"
                                />
                            </div>
                            <div>
                                <P className="text-white italic font-medium">
                                    {item.value}
                                </P>
                            </div>
                        </Quotes>
                    );
                case "list":
                    return (
                        <div key={index} className="mb-6">
                            {item.title && (
                                <P className="mb-1 md:text-lg xl:text-lg  font-semibold">
                                    {item.title}
                                </P>
                            )}
                            <ul className={cn("  pl-2")}>
                                {item.value.map((li, idx) => (
                                    <li
                                        className={cn(
                                            item.listClass,
                                            "list-disc mb-0.5  marker:text-lg"
                                        )}
                                        key={idx}
                                    >
                                        <P
                                            dangerouslySetInnerHTML={{
                                                __html: li,
                                            }}
                                            className="text-gray-700"
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                case "table":
                    return (
                        <div key={index} className="overflow-x-auto my-6">
                            <table className="table-auto border-collapse w-full text-left">
                                <thead className="bg-gray-100">
                                    <tr>
                                        {item.value.headers.map(
                                            (header, idx) => (
                                                <th
                                                    key={idx}
                                                    className="px-4 py-2 border"
                                                >
                                                    <P className="font-semibold">
                                                        {header}
                                                    </P>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.value.rows.map((row, rowIndex) => (
                                        <tr
                                            key={rowIndex}
                                            className="odd:bg-white border even:bg-gray-50"
                                        >
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex}>
                                                    <P
                                                        className="px-4 py-2 "
                                                        dangerouslySetInnerHTML={{
                                                            __html: cell,
                                                        }}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                case "img":
                    return (
                        <div key={index} className="my-6">
                            <img
                                src={item.value}
                                alt={item.altText}
                                className="w-full rounded"
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const {
        meta,
        title,
        titleClass,
        bannerImage,
        sections,
        published,
        readingTime,
    } = articlesDetails1;

    return (
        <>
            <Hero
                content={
                    <Container>
                        <div>
                            <H2 className={cn("my-2 w-full", titleClass)}>
                                {title}
                            </H2>

                            <Space xxsm={["0 0 2rem"]}>
                                <Flex.Container
                                    align="center"
                                    justify="space-between"
                                >
                                    <FlexItem>
                                        <P className="text-white">
                                            {published}
                                        </P>

                                        <P className="text-white">|</P>

                                        <P className="text-white">
                                            {readingTime}
                                        </P>
                                    </FlexItem>
                                </Flex.Container>
                            </Space>
                        </div>
                    </Container>
                }
                url={bannerImage.value}
                type={"image"}
                containerClass={"mb-0 xl:mb-0"}
                wrapperClass="  max-h-[60vh]  2xl:h-screen"
            />

            <Space xxsm={["0 0 0"]} xlm={["0 0 0rem"]}>
                <Section height="auto">
                    <Container>
                        <TOCContainer>
                            <H3>Table of Contents</H3>
                            <ul>
                                {sections.map((section, index) => (
                                    <li key={index}>
                                        <a
                                            className="no-underline mb-1"
                                            href={`#section-${index}`}
                                        >
                                            <P
                                                className="no-underline font-medium"
                                                dangerouslySetInnerHTML={{
                                                    __html: section.title,
                                                }}
                                            />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </TOCContainer>
                        <div>
                            {sections.map((section, index) => (
                                <div
                                    key={index}
                                    id={`section-${index}`}
                                    className="mb-5"
                                >
                                    <H3>{section.title}</H3>
                                    {renderContent(section.content)}
                                </div>
                            ))}
                        </div>
                        <Space xxsm={["3rem 0"]}>
                            <Flex.Container align="center">
                                {meta?.tags?.map((tag, index) => (
                                    <Tag key={index}>{tag}</Tag>
                                ))}
                            </Flex.Container>
                        </Space>
                        <div className="flex w-full justify-end">
                            <Subscribe />
                        </div>
                    </Container>
                </Section>
            </Space>
        </>
    );
};

export default BlogDetails;
