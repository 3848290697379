import { Autocomplete } from "@react-google-maps/api";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePickerBox from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { fromLatLng } from "react-geocode";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { RxCalendar } from "react-icons/rx";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { COUNTER_TIME, ERROR_MSG, ROLE } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import {
    emailRegEx,
    nameRegEx,
    passRegEX,
    phoneRegEx,
    pinRegEx,
} from "../../constants/regularExpression";
import {
    CONSUMER_OWNER_ONBOARDING_API_ENDPOINT,
    LOGIN,
    SIGNUP_CONSUMER_ENDPOINT,
    VALIDATE_API_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import {
    getCityAddressPinCode,
    getLatLongGeocoder,
} from "../../helpers/helpers";
import useAuth from "../../hooks/useAuth";
import cn from "../../lib/cn";
import { check } from "../../resources/js/images";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import request from "../../services/request";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import ReusableMap from "../../shared/ui/Map";
import OTP from "../../shared/ui/OTP";
import Stepper from "./Stepper";

const center = {
    lat: 18.5204303,
    lng: 73.8567437,
};
const DatePickerWrapper = styled.div`
    .react-calendar {
        font-family: Urbanist, sans serif;
    }

    .react-date-picker__wrapper {
        border: none !important;
    }
    .react-date-picker {
        height: 40px;
    }
    @media (min-width: 1024px) {
        .react-date-picker {
            height: 47px;
        }
    }

    @media (min-width: 1536px) {
        .react-date-picker {
            height: 53px;
        }
    }
    .react-calendar__tile {
        color: #171d39;
        font-size: 1.5rem;
    }
    .react-calendar__tile:disabled {
        background-color: white !important;
    }
    .react-calendar__tile--now {
        background-color: white !important;
    }
    .react-calendar__tile--active {
        background-color: #171d39 !important;
        color: white;
        border-radius: 55%;
    }
    .react-calendar__navigation button:disabled {
        background-color: white !important;
    }
    .react-calendar__navigation__prev2-button {
        display: none;
    }
    .react-calendar__navigation__next2-button {
        display: none;
    }
    abbr[title] {
        text-decoration: none;
        color: #b5bec6;
        font-size: 1rem;
        font-weight: 500;
    }
`;
/* @DESC:: Delete once Hero details verified @habib610 Fri October 18,2024 */
const HeroDetails = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(location?.pathname, dispatch);

    const [loading, setLoading] = useState(false);

    const [markerPointA, setMarkerPointA] = useState(center);
    const siteRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(
        state?.user?.plant?.status ? 3 : state.user ? 2 : 1
    );
    const [getOtp, setGetOtp] = useState(false);
    const [errorMessage, setErrorMsg] = useState("");
    const [countdown, setCountdown] = useState(5);
    const [autoFillAddress, setAutoFillAddress] = useState(
        "Pune, Maharashtra, India"
    );

    const [formData, setFormData] = useState({
        name: "",
        password: "",
        email: "",
        phoneNumber: "",
        electricalBill: "",
        date: "",
        address: "",
        otp: "",
    });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingPhone, setExistingPhone] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);

    // New states for address fields
    const [manualAddress, setManualAddress] = useState({
        customAddress: "",
        city: "",
        state: "",
        pincode: "",
    });
    const [useManualAddress, setUseManualAddress] = useState(false);

    const handleDate = (value) => {
        setFormData({ ...formData, date: value });
        setErrors({ ...errors, date: "" });
    };
    const handleManualAddressChange = (e) => {
        let newAddress = { ...manualAddress, [e.target.name]: e.target.value };
        setManualAddress(newAddress);
        const validationErrors = validateManualAddress(
            formData,
            blurred,
            newAddress
        );
        setErrors(validationErrors);
    };
    const [showResendLink, setShowResendLink] = useState(false);
    const validate = (formData, autoFillAddress, customAddress, verifyOtp) => {
        const newErrors = {};
        if (currentStep === 1) {
            if (!formData.name.trim()) {
                newErrors.name = "Name is required";
            }
            if (!formData.password.trim()) {
                newErrors.password = "Password is required";
            }
            if (!formData.email) {
                newErrors.email = "Email is Required";
            }
            if (formData.email && !emailRegEx.test(formData.email.trim())) {
                newErrors.email = "Email is invalid";
            }
            if (!formData.phoneNumber.trim()) {
                newErrors.phoneNumber = "Phone number is required";
            } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
                newErrors.phoneNumber = "Phone number is invalid";
            }
            if (verifyOtp) {
                if (!formData.otp) {
                    newErrors.otp = "OTP is required";
                } else if (!formData.otp && formData.otp.length !== 6) {
                    newErrors.otp = "Incomplete OTP";
                }
            }
        }

        return newErrors;
    };

    useEffect(() => {
        const getProfile = async () => {
            if (state.user && isAuth) {
                let status = state?.user?.plant?.status;
                if (status) {
                    setCurrentStep(3);
                } else {
                    setCurrentStep(2);
                }
            }
        };

        getProfile();
    }, []);

    const handleGetOTP = () => {
        setLoading(true);
        const reqData = {
            name: formData.name,
            email: formData.email,
            phone: `91${formData.phoneNumber}`,
            password: formData.password,
        };
        let endpoint = SIGNUP_CONSUMER_ENDPOINT;
        request
            .post({
                endpoint,
                body: reqData,
            })
            .then((response) => {
                toast.success("OTP has been sent!");
                setErrors({});
                setGetOtp(true);
                setShowResendLink(true);
                setCountdown(COUNTER_TIME);
                setTimeout(() => {
                    setShowResendLink(false);
                }, countdown * 1000);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            });
    };

    const handleSignup = async () => {
        setLoading(true);
        try {
            let reqData = {
                name: formData.name,
                email: formData.email,
                phone: `91${formData.phoneNumber}`,
                referralCode: null,
                password: formData.password,
                otp: formData.otp,
                dateOfBirth: "18-10-1988",
            };
            let endpoint = SIGNUP_CONSUMER_ENDPOINT;
            await request.post({
                endpoint,
                body: reqData,
            });

            await auth.login({
                username: formData.email,
                password: formData.password,
                otpEnable: false,
            });

            setCurrentStep(2);

            dispatch({
                type: REMOVE_REDIRECT_PATH,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err || ERROR_MSG);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateWithBlurred(formData, blurred, getOtp);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            if (currentStep === 1) {
                if (getOtp) {
                    handleSignup();
                } else {
                    handleGetOTP();
                }
            }
        }
    };

    const validateBillAndDate = (formData, blurred) => {
        const newErrors = {};
        if (blurred["electricalBill"]) {
            if (!formData.electricalBill.trim()) {
                newErrors.electricalBill = "Electricity Bill is required";
            } else if (
                formData.electricalBill &&
                isNaN(formData.electricalBill)
            ) {
                newErrors.electricalBill = "Bill must be valid number";
            } else if (formData.electricalBill < 10000) {
                newErrors.electricalBill = "Min bill is 10,000";
            } else if (formData.electricalBill > 1000000) {
                newErrors.electricalBill = "Max bill is 10,00,000";
            }
        }

        if (blurred["date"]) {
            if (!formData?.date) {
                newErrors.date = "Date is required";
            } else if (moment(formData.date).isValid() === false) {
                newErrors.date = "Date is invalid!";
            } else {
                const currentMoment = moment().startOf("day").unix();
                const maxTimeEndDays = moment()
                    .add(13, "days")
                    .endOf("day")
                    .unix();
                const userMoment = moment(formData.date).startOf("days").unix();
                if (currentMoment > userMoment) {
                    newErrors.date = "Past date is invalid!";
                } else if (maxTimeEndDays < userMoment) {
                    newErrors.date = "Date can't be more than 2 weeks!";
                }
            }
        }

        return newErrors;
    };

    const validateWithBlurredAddress = (formData, blurred, sitRefAddress) => {
        let newErrors = {};
        newErrors = { ...validateBillAndDate(formData, blurred) };
        if (blurred["address"]) {
            if (!sitRefAddress) {
                newErrors.address = "Address is required";
            }
        }
        return newErrors;
    };

    const validateManualAddress = (formData, blurred, customAddress) => {
        let newErrors = {};

        newErrors = { ...validateBillAndDate(formData, blurred) };
        if (blurred["customAddress"]) {
            if (!customAddress.customAddress) {
                newErrors.customAddress = "Address is required";
            }
        }
        if (blurred["city"]) {
            if (!customAddress.city) {
                newErrors.city = "City is required";
            }
        }
        if (blurred["state"]) {
            if (!customAddress.state) {
                newErrors.state = "State is required";
            }
        }
        if (blurred["pincode"]) {
            if (!customAddress.pincode) {
                newErrors.pincode = "Pin code is required";
            } else if (
                customAddress.pincode &&
                !pinRegEx.test(customAddress.pincode)
            ) {
                newErrors.pincode = "Invalid pin code";
            }
        }

        return newErrors;
    };

    const handleCompleteOnboarding = async () => {
        const touched = {
            electricalBill: true,
            address: true,
            customAddress: true,
            city: true,
            state: true,
            pincode: true,
            date: true,
        };
        setBlurred(touched);
        let formValidationError;
        if (!useManualAddress) {
            formValidationError = validateWithBlurredAddress(
                formData,
                touched,
                siteRef?.current?.value
            );
            setErrors(formValidationError);
        } else {
            formValidationError = validateManualAddress(
                formData,
                touched,
                manualAddress
            );
            setErrors(formValidationError);
        }
        if (Object.keys(formValidationError).length !== 0) {
            return;
        }

        try {
            let optionalResult = {};
            if (!useManualAddress) {
                if (
                    !autoFillAddress?.pinCode ||
                    !autoFillAddress?.city ||
                    !autoFillAddress?.state
                ) {
                    let res = await fromLatLng(
                        autoFillAddress?.lat,
                        autoFillAddress?.lng
                    );
                    optionalResult = {
                        ...getCityAddressPinCode(
                            res.results[0].address_components
                        ),
                    };
                }
            }

            let reqData = {
                date: `${moment(formData.date).unix()}`,
                bill: formData.electricalBill,
            };

            if (useManualAddress) {
                const latLongGeoCoder = await getLatLongGeocoder(
                    `${manualAddress.customAddress}, ${manualAddress.city}, ${manualAddress.state}, ${manualAddress.pincode}`
                );
                reqData.city = manualAddress.city;
                reqData.state = manualAddress.state;
                reqData.pinCode = manualAddress.pincode;
                reqData.address = manualAddress.customAddress;
                reqData.latitude = latLongGeoCoder.lat;
                reqData.longitude = latLongGeoCoder.lng;
            } else {
                const geoResult = await getLatLongGeocoder(
                    siteRef?.current?.value
                );
                reqData.city = geoResult?.city || optionalResult?.city;
                reqData.state = geoResult?.state || optionalResult?.state;
                reqData.pinCode = geoResult?.pinCode || optionalResult?.pinCode;
                reqData.latitude = geoResult?.lat;
                reqData.longitude = geoResult?.lng;
                reqData.address = siteRef.current.value;
            }
            setLoading(true);
            await request.authPost({
                endpoint: CONSUMER_OWNER_ONBOARDING_API_ENDPOINT,
                body: reqData,
            });
            setLoading(false);
            setCurrentStep(3);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handlePlaceChanged = async () => {
        const res = await getLatLongGeocoder(siteRef.current.value);
        setAutoFillAddress(res);
        setMarkerPointA(res);
    };

    const resentOtpHandler = async (mode) => {
        const validationErrors = validate(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let val = `91${formData.phoneNumber}`;
            let credentials = val;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };

    const handleBlur = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "email") {
            if (!emailRegEx.test(value.toLowerCase())) {
                setErrors({ ...errors, [name]: "Enter a valid email!" });
                setCheckEmail(false);
                setExistingEmail(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            email: value,
                            roles: [
                                ROLE.CONSUMER_USER,
                                ROLE.CONSUMER_OWNER,
                                ROLE.COMPANY_AGENT,
                                ROLE.CONSUMER_ADMIN,
                            ],
                        },
                    })
                    .then((res) => {
                        setCheckEmail(true);
                        setExistingEmail(false);
                        setEmailErr("");
                    })
                    .catch((err) => {
                        setCheckEmail(false);
                        setExistingEmail(true);
                        setEmailErr(
                            typeof err === "string"
                                ? err
                                : "Email is already in use. Click "
                        );
                    });
            }
        } else if (name === "phoneNumber") {
            if (!phoneRegEx.test(value)) {
                setErrors({ ...errors, [name]: "Enter a valid phone number!" });
                setCheckPhone(false);
                setExistingPhone(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            phone: `91${value}`,
                            roles: [
                                ROLE.CONSUMER_USER,
                                ROLE.CONSUMER_OWNER,
                                ROLE.COMPANY_AGENT,
                                ROLE.CONSUMER_ADMIN,
                            ],
                        },
                    })
                    .then((res) => {
                        setCheckPhone(true);
                        setExistingPhone(false);
                        setPhoneErr("");
                    })
                    .catch((err) => {
                        setCheckPhone(false);
                        setExistingPhone(true);
                        setPhoneErr(
                            typeof err === "string"
                                ? err
                                : "Phone is already in use. Click "
                        );
                    });
            }
        }
    };

    /* @DESC:: Staring manual here @habib610 Sat October 19,2024 */
    const [blurred, setBlurred] = useState({
        name: false,
        password: false,
        email: false,
        phoneNumber: false,
        electricalBill: false,
        address: false,
        city: false,
        state: false,
        pincode: false,
    });

    const handleCustomBlur = (e) => {
        const name = e.target.name;
        if (!blurred[name]) {
            const newBlurredObj = { ...blurred, [name]: true };
            setBlurred(newBlurredObj);
            const validatedData = validateWithBlurred(
                formData,
                newBlurredObj,
                getOtp,
                autoFillAddress
            );
            setErrors(validatedData);
        } else if (
            blurred[name] &&
            (name === "email" || name === "phoneNumber")
        ) {
            handleBlur(e);
        }
    };

    const handleAddressBlur = (e) => {
        const name = e.target.name;
        if (!blurred[name]) {
            const newBlurredObj = { ...blurred, [name]: true };
            setBlurred(newBlurredObj);
            const validatedData = validateManualAddress(
                formData,
                newBlurredObj,
                manualAddress
            );
            setErrors(validatedData);
        }
    };
    const handleChange = (e) => {
        let newFormData = { ...formData, [e.target.name]: e.target.value };
        setFormData(newFormData);
        if (currentStep === 1) {
            let validatedData = validateWithBlurred(
                newFormData,
                blurred,
                getOtp,
                autoFillAddress
            );
            setErrors(validatedData);
        } else {
            let validatedData = validateWithBlurredAddress(
                newFormData,
                blurred,
                siteRef?.current?.value
            );
            setErrors(validatedData);
        }

        if (e.target.name === "email") {
            setEmailErr("");
        } else if (e.target.name === "phoneNumber") {
            setPhoneErr("");
        }
    };

    const validateWithBlurred = (formData, blurred, verifyOtp) => {
        const newErrors = {};
        if (currentStep === 1) {
            if (blurred["name"]) {
                if (!formData.name.trim()) {
                    newErrors.name = "Name is required";
                } else if (!nameRegEx.test(formData.name.trim())) {
                    newErrors.name =
                        "Name should start with letter and not contain any special characters or numbers";
                }
            }
            if (blurred["password"]) {
                if (!formData.password.trim()) {
                    newErrors.password = "Password is required";
                } else if (!passRegEX.test(formData.password)) {
                    newErrors.password =
                        "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
                }
            }

            if (blurred["email"]) {
                if (!formData.email) {
                    newErrors.email = "Email is Required";
                }
                if (formData.email && !emailRegEx.test(formData.email.trim())) {
                    newErrors.email = "Email is invalid";
                }
            }
            if (blurred["phoneNumber"]) {
                if (!formData.phoneNumber.trim()) {
                    newErrors.phoneNumber = "Phone number is required";
                } else if (!phoneRegEx.test(formData.phoneNumber)) {
                    newErrors.phoneNumber = "Phone number is invalid";
                }
            }
            if (verifyOtp) {
                if (!formData.otp) {
                    newErrors.otp = "OTP is required";
                } else if (formData.otp.length !== 6) {
                    newErrors.otp = "Incomplete OTP";
                }
            }
        }

        return newErrors;
    };

    const renderStepFields = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <TextField
                                label={"Full Name"}
                                value={formData?.name}
                                onChange={handleChange}
                                onBlur={handleCustomBlur}
                                name={"name"}
                                type={"text"}
                                errorMsg={errors?.name}
                                required
                            />
                            <div>
                                <TextField
                                    label={"Email Id"}
                                    value={formData?.email}
                                    onChange={handleChange}
                                    name={"email"}
                                    type={"text"}
                                    errorMsg={errors?.email || emailErr}
                                    onBlur={handleCustomBlur}
                                    required
                                    errorButton={
                                        emailErr && (
                                            <button
                                                onClick={() =>
                                                    history.push(LOGIN)
                                                }
                                                type="button"
                                                className=" text-blue text-xs md:text-sm underline inline-block"
                                            >
                                                Login
                                            </button>
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6 ">
                            <div>
                                <TextField
                                    label={"Phone Number"}
                                    value={formData?.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleCustomBlur}
                                    name={"phoneNumber"}
                                    type={"text"}
                                    errorMsg={errors?.phoneNumber || phoneErr}
                                    helperMsg={
                                        "An OTP will be sent to your phone number for verification"
                                    }
                                    required
                                    errorButton={
                                        phoneErr && (
                                            <button
                                                onClick={() =>
                                                    history.push(LOGIN)
                                                }
                                                type="button"
                                                className=" text-blue text-xs md:text-sm underline inline-block"
                                            >
                                                Login
                                            </button>
                                        )
                                    }
                                />
                            </div>

                            <TextField
                                label={"Password"}
                                value={formData?.password}
                                onChange={handleChange}
                                onBlur={handleCustomBlur}
                                name={"password"}
                                errorMsg={errors?.password}
                                required
                                type={showPassword ? "text" : "password"}
                                icon={
                                    showPassword ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <IoEyeSharp />
                                    )
                                }
                                iconWrapperClass={
                                    "right-0 px-[2rem] rounded-md bg-gray-100 my-0.1 mx-px"
                                }
                                iconChange={() =>
                                    setShowPassword((pass) => !pass)
                                }
                            />
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <TextField
                                label={"Electrical Bill"}
                                value={formData?.electricalBill}
                                onChange={handleChange}
                                onBlur={handleCustomBlur}
                                name={"electricalBill"}
                                type={"text"}
                                errorMsg={errors?.electricalBill}
                                required
                            />
                            <div className="w-full">
                                <div className="flex flex-col">
                                    <label
                                        className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                                        htmlFor={"date"}
                                    >
                                        Schedule a site visit
                                    </label>
                                    <div
                                        className={` relative px-px py-px  border   rounded-lg ${
                                            errors.date
                                                ? "border-red"
                                                : "border-blue-400"
                                        } text-btn-s`}
                                    >
                                        <DatePickerWrapper>
                                            <DatePickerBox
                                                onChange={handleDate}
                                                value={formData?.date}
                                                dayPlaceholder="dd"
                                                name="date"
                                                monthPlaceholder="mm"
                                                format="dd/MM/yyyy"
                                                yearPlaceholder="yyyy"
                                                minDate={new Date()}
                                                clearIcon={null}
                                                calendarIcon={
                                                    <RxCalendar size={20} />
                                                }
                                                className={`w-full flex border-none rounded-lg text-primary bg-white h-[40px] md:h-[53px]`}
                                                calendarClassName="relative top-1 !border-2 !border-blue-400 bg-white rounded-lg"
                                                wrapperClassName={`bg-white !border-none w-full`}
                                                inputClassName="text-primary border-none w-full"
                                            />
                                        </DatePickerWrapper>
                                    </div>
                                    {errors.date && (
                                        <span className="text-red text-btn-s">
                                            {errors.date}*
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2 ">
                            {!useManualAddress ? (
                                isLoaded ? (
                                    <>
                                        <div>
                                            <label className="text-secondary font-normal text-sm sm:text-base xl:text-lg  ">
                                                Enter Address{" "}
                                                <span className="text-red">
                                                    *
                                                </span>
                                            </label>
                                            <Autocomplete
                                                onPlaceChanged={
                                                    handlePlaceChanged
                                                }
                                            >
                                                <div
                                                    className={cn(
                                                        "relative px-px py-px border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-btn-s bg-custom-gradient mt-0.8",
                                                        {
                                                            "border-red":
                                                                errors.address,
                                                        }
                                                    )}
                                                >
                                                    <input
                                                        className={`border-none w-full  rounded-lg px-1  py-1  lg:py-1.2
                                                        text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl
                    `}
                                                        onBlur={(e) => {
                                                            if (
                                                                !blurred[
                                                                    "address"
                                                                ]
                                                            ) {
                                                                setBlurred({
                                                                    ...blurred,
                                                                    address: true,
                                                                });
                                                                setErrors(
                                                                    validateWithBlurredAddress(
                                                                        formData,
                                                                        {
                                                                            ...blurred,
                                                                            address: true,
                                                                        },
                                                                        siteRef
                                                                            ?.current
                                                                            ?.value
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        label="Enter Your Address"
                                                        id="siteLocationId"
                                                        shadow={false}
                                                        ref={siteRef}
                                                        onChange={(e) =>
                                                            e.target.value &&
                                                            setErrors({
                                                                ...errors,
                                                                address: "",
                                                            })
                                                        }
                                                        isError={errors.address}
                                                    />
                                                </div>
                                            </Autocomplete>
                                        </div>

                                        {errors.address && (
                                            <span className="text-red text-btn-s">
                                                {errors.address}*
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    <P>Loading...</P>
                                )
                            ) : (
                                <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-2">
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        value={manualAddress.customAddress}
                                        onBlur={handleAddressBlur}
                                        onChange={handleManualAddressChange}
                                        name="customAddress"
                                        errorMsg={errors.customAddress}
                                    />
                                    <TextField
                                        label="City"
                                        onBlur={handleAddressBlur}
                                        placeholder="City"
                                        value={manualAddress.city}
                                        onChange={handleManualAddressChange}
                                        name="city"
                                        errorMsg={errors.city}
                                    />
                                    <TextField
                                        label="State"
                                        placeholder="State"
                                        onBlur={handleAddressBlur}
                                        value={manualAddress.state}
                                        onChange={handleManualAddressChange}
                                        name="state"
                                        errorMsg={errors.state}
                                    />
                                    <TextField
                                        label="Pincode"
                                        placeholder="Pincode"
                                        value={manualAddress.pincode}
                                        onChange={handleManualAddressChange}
                                        name="pincode"
                                        errorMsg={errors.pincode}
                                        onBlur={handleAddressBlur}
                                    />
                                </div>
                            )}
                            <div>
                                <button
                                    onClick={() => {
                                        setManualAddress({
                                            city: "",
                                            state: "",
                                            pincode: "",
                                            customAddress: "",
                                        });
                                        setUseManualAddress(
                                            (useManualAddress) =>
                                                !useManualAddress
                                        );
                                    }}
                                    type="button"
                                    className="mt-2 text-blue text-xs md:text-sm underline inline-block"
                                >
                                    {useManualAddress
                                        ? "Select address on Google Map"
                                        : "Unable to find address? Enter manually"}
                                </button>
                            </div>
                        </div>
                        {!useManualAddress && (
                            <div className="grid grid-cols-1 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                                <ReusableMap
                                    center={markerPointA}
                                    dimensions="w-[100%] h-[200px]"
                                    address={autoFillAddress}
                                    callback={() => setIsLoaded(true)}
                                />
                            </div>
                        )}
                    </>
                );
            case 3:
                return (
                    <div className="flex flex-col justify-center items-center mt-5">
                        <img
                            src={check}
                            className="w-44 h-44"
                            alt="check_icon"
                        />
                        <P className={"text-center w-[70%] my-5"}>
                            An email will be sent to your registered email id
                            and we will contact you shortly to confirm your site
                            booking!
                        </P>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <Container>
            <div className="flex justify-center xl:justify-between flex-wrap flex-col xl:flex-row items-center xl:gap-9 py-[10rem]  lg:py-[13rem] xl:py-[15rem] 2xl:py-[18rem]">
                <div className="w-full hidden xl:w-[30%] xl:flex flex-col gap-4.8 xl:ml-4">
                    <H1 className={" text-white "}>Onboarding</H1>
                    <P className={"text-white"}>
                        Please share your details to get onboarded to our
                        platform
                    </P>
                </div>
                <div className="flex-1 w-full xl:w-1/5 2xl:w-1/2 xl:mr-4">
                    <div className="w-full mx-auto bg-white rounded-1.5 shadow-md p-2 md:py-4.6 md:px-3">
                        <div className="md:mx-4">
                            <Stepper currentStep={currentStep} />
                        </div>

                        <form onSubmit={handleSubmit}>
                            {renderStepFields()}

                            {getOtp && currentStep === 1 && (
                                <>
                                    <OTP
                                        otp={formData.otp}
                                        onChange={handleChange}
                                        error={errorMessage}
                                        errorMessage={errors.otp}
                                        handleResentOTP={resentOtpHandler}
                                    />
                                </>
                            )}

                            <div className="mt-2">
                                {currentStep === 1 && (
                                    <Button
                                        onClick={(e) => handleSubmit(e)}
                                        type="submit"
                                        className="w-full flex justify-center px-1 sm:px-1.8 2xl:px-2 py-0.8 sm:py-1.2 xl:py-1.2 "
                                        disabled={
                                            loading ||
                                            (currentStep === 1 &&
                                                (!checkEmail ||
                                                    !checkPhone ||
                                                    Object.keys(errors).length >
                                                        0))
                                        }
                                        isLoading={loading}
                                    >
                                        {getOtp ? "Submit OTP" : "Get OTP"}
                                    </Button>
                                )}

                                {currentStep === 2 && (
                                    <Button
                                        onClick={(e) =>
                                            handleCompleteOnboarding(e)
                                        }
                                        type="submit"
                                        className="w-full flex justify-center px-1 sm:px-1.8 2xl:px-2 py-0.8 sm:py-1.2 xl:py-1.2 "
                                        disabled={
                                            loading ||
                                            (currentStep === 1 &&
                                                (!checkEmail ||
                                                    !checkPhone ||
                                                    Object.keys(errors).length >
                                                        0))
                                        }
                                        isLoading={loading}
                                    >
                                        Submit Details
                                    </Button>
                                )}

                                {currentStep === 3 && (
                                    <Button
                                        onClick={(e) => handleSubmit(e)}
                                        type="submit"
                                        className="w-full flex justify-center px-1 sm:px-1.8 2xl:px-2 py-0.8 sm:py-1.2 xl:py-1.2"
                                    >
                                        Done
                                    </Button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
