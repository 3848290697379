import { useField } from "formik";
import React from "react";

const FormInputBottomLine = ({
    label,
    value,
    onChange,
    helperMsg,
    errorMsg,
    required,
    icon,
    iconChange,
    errorButton,
    disabled,
    iconWrapperClass = "right-3 flex items-center",
    ...rest
}) => {
    const [field, meta] = useField(rest);
    return (
        <div className="flex flex-col">
            <label
                className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                htmlFor={field.name}
            >
                {label} {required && <span className="text-red">*</span>}
            </label>
            <div
                className={`relative border-b-2  ${
                    meta.touched && meta.error
                        ? "border-red"
                        : disabled
                        ? "border-gray-200"
                        : "border-gray-120"
                }    focus-within::outline-none  focus-within:border-blue text-btn-s outline-none ring-0 transition-all duration-300`}
            >
                <input
                    disabled={disabled}
                    className={`border-none w-full outline-none ring-0  px-1 py-1  lg:py-1.2
                    text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl
                    disabled:bg-gray-100
                    `}
                    {...rest}
                    {...field}
                />
                {icon && (
                    <div className={`cursor-pointer`}>
                        <span
                            role="button"
                            onClick={() => iconChange()}
                            className={`absolute cursor-pointer inset-y-0 ${iconWrapperClass} flex items-center`}
                        >
                            {icon}
                        </span>
                    </div>
                )}
            </div>
            {meta.touched && meta.error && (
                <>
                    <span className="text-red text-sm xl:text-base flex items-center flex-wrap gap-0.5">
                        {meta.error}* {errorButton}
                    </span>
                </>
            )}
            {helperMsg && (
                <span className="text-xs text-gray-400">{helperMsg}</span>
            )}
        </div>
    );
};

export default FormInputBottomLine;
