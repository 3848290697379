import React from "react";
import styled from "styled-components";
import P from "../../typography/P";

const LabelWrap = styled.label`
    display: inline-flex;
    align-items: ${(props) => (props.isSubText ? "flex-start" : "center")};
    cursor: pointer;
    .radioInput {
        display: none;
    }
    label {
        display: none;
    }
    .customRadio {
        ::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${(props) =>
                props.theme.color[props.activeColor]};
            border-radius: 100%;
            transform: scale(0);
            transition: all linear 0.2s;
        }
    }
    .radioInput:checked + .customRadio {
        ::after {
            transform: scale(1);
        }
    }
    .radioInput:disabled + .customRadio {
        border: 2px solid ${(props) => props.theme.color.lightGrey};
        opacity: 0.7;
        cursor: not-allowed;
        ::after {
            background-color: ${(props) => props.theme.color.primary};
        }
    }
`;

const RadioInput = ({
    label,
    id,
    onChange,
    color = "black",
    backgroundColor,
    activeColor = "cyan500",
    ...rest
}) => {
    return (
        <LabelWrap
            htmlFor={id}
            activeColor={activeColor}
            backgroundColor={backgroundColor}
        >
            <input
                onChange={onChange}
                type="radio"
                id={id}
                {...rest}
                className="radioInput"
            />
            <div className="customRadio border-2 border-gray-400 rounded-full p-0.2 h-1.8 w-1.8 mr-0.8"></div>
            {label && (
                <div>
                    <label htmlFor={id}></label>
                    <P>{label}</P>
                </div>
            )}
        </LabelWrap>
    );
};

export default RadioInput;
