import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MSG, queryData } from "../../../constants/common";
import { ENQUIRY_CONSUMER_ENDPOINT } from "../../../constants/routes";
import request from "../../../services/request";
import H4 from "../../typography/H4";
import P from "../../typography/P";
import Button from "../Button";
import FormInputBottomLine from "./FormInputBottomLine";
import RadioInput from "./RadioInput";
import { enquiryValidator } from "./schema";

const ContactFormFormik = ({
    wrapperClass = " w-full lg:bg-white lg:rounded-1.5 lg:shadow-md lg:py-5.6 lg:px-4",
    disabled = false,
    enquiry,
    callback,
}) => {
    const [queryType, setQueryType] = useState(queryData[0]);

    const handleSubmit = async (values, actions) => {
        try {
            let body = {
                consumerType: queryType
                    .toUpperCase()
                    .trim()
                    .replaceAll(" ", "_"),
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                averageMonthlyBill: values.averageMonthlyBill,
                locationPincode: values.locationPincode,
            };

            let res = await request.post({
                endpoint: ENQUIRY_CONSUMER_ENDPOINT,
                body,
            });
            actions.resetForm();
            toast.success(res?.data?.message || "Lead added successfully");
            setQueryType(queryData[0]);
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };

    return (
        <div className={` mx-auto ${wrapperClass}  `}>
            <>
                <H4 className={"font-semibold  mb-1.6"}>
                    Fill out your details and we'll get back to you ASAP!
                </H4>

                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: "",
                        mobile: "",
                        email: "",
                        averageMonthlyBill: "",
                        locationPincode: "",
                    }}
                    validationSchema={enquiryValidator}
                >
                    {({ isSubmitting, isValid }) => (
                        <Form className=" rounded-r-2 border-y-blue-500 flex-1">
                            <P className="mb-1.2">I'm looking for</P>
                            <div className="grid  gap-2 lg:gap-1 xl:gap-0.1 grid-cols-2  lg:grid-cols-none lg:flex justify-between   2xl:grid-cols-4">
                                {queryData.map((item, i) => (
                                    <RadioInput
                                        defaultChecked={item === queryData[0]}
                                        label={item}
                                        id={`${i}query_term`}
                                        name="query"
                                        color="primary"
                                        activeColor="black800"
                                        value={item}
                                        onChange={(e) => {
                                            setQueryType(e.target.value);
                                        }}
                                    />
                                ))}
                            </div>
                            <div className="grid gird-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2 gap-y-4">
                                <FormInputBottomLine
                                    name="name"
                                    placeholder="Name*"
                                />
                                <FormInputBottomLine
                                    name="mobile"
                                    placeholder="Mobile Number*"
                                />
                                <FormInputBottomLine
                                    name="email"
                                    placeholder="Email*"
                                />

                                <FormInputBottomLine
                                    name="locationPincode"
                                    placeholder="Location Pin Code*"
                                />
                                <FormInputBottomLine
                                    name="averageMonthlyBill"
                                    placeholder="Avg Monthly Electricity Bill*"
                                />
                                <div className="flex flex-col justify-end ">
                                    <Button
                                        disabled={isSubmitting || !isValid}
                                        isLoading={isSubmitting}
                                        type="submit"
                                        className="w-full py-1 "
                                    >
                                        Show Your Interest
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        </div>
    );
};

export default ContactFormFormik;
